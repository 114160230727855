import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
} from '@mui/material';

const SelectDemographic = ({ demographicData, setDemographicData, cities, counties }) => {
  const [otherGender, setOtherGender] = useState('');
  const [otherRace, setOtherRace] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDemographicData(prev => ({ ...prev, [name]: value }));
  };

  const handleRaceChange = (event) => {
    const { value, checked } = event.target;
    setDemographicData(prev => ({
      ...prev,
      race: checked
        ? [...prev.race, value]
        : prev.race.filter(item => item !== value)
    }));
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Typography variant="h6" className="text-center mb-4 font-bold text-light-green">
        Demographic Information (Optional)
      </Typography>
      <Typography variant="body2" className="text-center mb-4">
        To better understand and serve our community, we ask for some demographic information. Providing this information is entirely optional, but it helps us ensure that our programs are inclusive and meet the diverse needs of all our members. Your responses will be kept confidential and used solely for the purpose of improving our initiatives and services.
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" className="font-bold text-light-green">What is your age group?</FormLabel>
            <RadioGroup
              name="ageGroup"
              value={demographicData.ageGroup}
              onChange={handleChange}
            >
              {['18-24', '25-34', '35-44', '45-54', '55-64', '65 and over', 'Prefer not to say'].map((age) => (
                <FormControlLabel key={age} value={age} control={<Radio />} label={age} />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" className="font-bold text-light-green">What is your gender?</FormLabel>
            <RadioGroup
              name="gender"
              value={demographicData.gender}
              onChange={handleChange}
            >
              {['Female', 'Male', 'Non-binary', 'Prefer not to say'].map((gender) => (
                <FormControlLabel key={gender} value={gender} control={<Radio />} label={gender} />
              ))}
              <FormControlLabel
                value="otherGender"
                control={<Radio />}
                label={
                  <TextField
                    variant="standard"
                    placeholder="Prefer to self-describe"
                    value={otherGender}
                    onChange={(e) => {
                      setOtherGender(e.target.value);
                      setDemographicData(prev => ({ ...prev, gender: 'otherGender', otherGenderDescription: e.target.value }));
                    }}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      <FormControl component="fieldset">
        <FormLabel component="legend" className="font-bold text-light-green">What is your race/ethnicity? (Select all that apply)</FormLabel>
        <Grid container spacing={2}>
          {['Asian', 'Black or African American', 'Hispanic or Latino', 'Native American or Alaska Native', 'Native Hawaiian or Other Pacific Islander', 'White', 'Middle Eastern or North African', 'Multiracial'].map((race) => (
            <Grid item xs={12} sm={6} key={race}>
              <FormControlLabel
                control={<Checkbox checked={demographicData.race.includes(race)} onChange={handleRaceChange} value={race} />}
                label={race}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={demographicData.race.includes('otherRace')} onChange={handleRaceChange} value="otherRace" />}
              label={
                <TextField
                  variant="standard"
                  placeholder="Prefer to self-describe"
                  value={otherRace}
                  onChange={(e) => {
                    setOtherRace(e.target.value);
                    setDemographicData(prev => ({
                      ...prev,
                      race: [...prev.race.filter(r => r !== 'otherRace'), 'otherRace'],
                      otherRaceDescription: e.target.value
                    }));
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </FormControl>

      <br />

      <Autocomplete
        freeSolo
        options={cities}
        renderInput={(params) => <TextField {...params} label="City (type to search)" />}
        value={demographicData.city}
        onChange={(event, newValue) => {
          setDemographicData(prev => ({ ...prev, city: newValue }));
        }}
      />

      <Autocomplete
        freeSolo
        options={counties}
        renderInput={(params) => <TextField {...params} label="County (type to search)" />}
        value={demographicData.county}
        onChange={(event, newValue) => {
          setDemographicData(prev => ({ ...prev, county: newValue }));
        }}
      />
    </div>
  );
};

export default SelectDemographic;