import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import AddNewOrganization from "./AddNewOrganization";
import Axios from "axios";
import ".././config";

export default function SelectOrganization({
  addOrganization,
  setSelectedOrg,
  selectedOrg,
  completedOrgs,
  setCompletedOrgs,
  previousOrgs,
  setPreviousOrgs,
  organizationError,
  setOrganizationError,
}) {
  const [organizations, setOrganizations] = useState([]);
  const [profilePosition, setProfilePosition] = useState("");
  const [showAddNewOrg, setShowAddNewOrg] = useState(false);

  useEffect(() => {
    const getOrganizations = async () => {
      try {
        const res = await Axios.get(
          `${global.config.domain}/express-api/verified-organizations`
        );
        const fetchedOrganizations = res.data.orgs;
        setOrganizations(fetchedOrganizations);
      } catch (error) {
        console.error(error);
      }
    };
    getOrganizations();
  }, []);

  const handleAddOrganization = () => {
    if (isValid()) {
      addOrganization({
        ...selectedOrg,
        role: profilePosition,
      });
      setSelectedOrg(null);
      setProfilePosition("");
      setOrganizationError(false);
    }
  };

  const removeOrganization = async (org) => {
    if (!previousOrgs) return;
    
    const matchingOrg = previousOrgs.find(
      (previousOrg) => previousOrg?._id === org._id
    );
    if (matchingOrg) {
      setCompletedOrgs((prevState) =>
        prevState.filter((previousOrg) => previousOrg._id !== org._id)
      );
      await Axios.put(`${global.config.domain}/express-api/remove-member`, {
        orgId: matchingOrg._id,
      });
    } else {
      setCompletedOrgs((prevState) =>
        prevState.filter((previousOrg) => previousOrg._id !== org._id)
      );
    }
  };

  const handleOrgChange = (event, newValue) => {
    if (newValue && newValue.name === "Not Listed (create new)") {
      setShowAddNewOrg(true);
      setSelectedOrg(newValue);
    } else {
      setSelectedOrg(newValue);
      setShowAddNewOrg(false);
    }
    setOrganizationError(false);
  };

  const isValid = () => selectedOrg && profilePosition;

  return (
    <>
      <div className="flex justify-center">
        <div className="max-w-3xl w-full flex flex-col gap-y-3">
          <div>
            <Typography variant="subtitle1" className="font-bold text-light-green">
              Join your organization:
            </Typography>
          <br />          
          </div>
          <FormControl
            sx={{ width: "100%" }}
            size="small"
            error={organizationError}
          >
            <div className="flex flex-col gap-y-3">
              <Autocomplete
                options={[
                  { name: "Not Listed (create new)" },
                  ...organizations,
                ]}
                size="small"
                onChange={handleOrgChange}
                value={selectedOrg}
                getOptionLabel={(option) => option?.name || ""}
                renderOption={(props, option) => (
                  <li {...props} style={{ color: option.name === "Not Listed (create new)" ? "#668c3c" : "inherit" }}>
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select organization (type to search)"
                    error={organizationError}
                  />
                )}
              />

              {selectedOrg && selectedOrg.name !== "Not Listed (create new)" && (
                <>
                  <TextField
                    margin="none"
                    id="position"
                    label="Position/Title*"
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={profilePosition}
                    error={organizationError}
                    onChange={(event) => setProfilePosition(event.target.value)}
                  />

                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={!isValid()}
                    onClick={handleAddOrganization}
                    sx={{
                      width: "fit-content",
                      textTransform: "none",
                      marginLeft: "auto",
                    }}
                  >
                    Add Organization
                  </Button>
                </>
              )}
            </div>
            {organizationError && (
              <FormHelperText>You must add an organization</FormHelperText>
            )}
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexWrap: "wrap",
              gap: 1,
              mt: 1,
            }}
          >
            {completedOrgs && completedOrgs.map((org, index) => (
              <Chip
                key={index}
                label={`${org.name}${org.role ? ` - ${org.role}` : ""}`}
                size="small"
                deleteIcon={<Cancel />}
                onDelete={() => removeOrganization(org)}
              />
            ))}
          </Box>
        </div>
      </div>
      {showAddNewOrg && (
        <AddNewOrganization
          addOrganization={(newOrg) => {
            addOrganization(newOrg);
            setShowAddNewOrg(false);
            setSelectedOrg(null);
          }}
          setShowAddNewOrg={setShowAddNewOrg}
        />
      )}
    </>
  );
}