import React from "react";
import {
  Chip,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  TextField,
} from "@mui/material";

export default function SelectMemberGains({
  selectedTags,
  setSelectedTags,
  memberGainsError,
  setMemberGainsError,
  otherGains,
  setOtherGains,
}) {
  const memberGains = [
    "Model Program Examples",
    "Forums/Discussions",
    "Knowledge - School Gardens",
    "Knowledge - Food Education",
    "Knowledge - Local Food Procurement for Child Nutrition Programs",
    "Knowledge - Selling to the School Food Market (Child Nutrition Programs)",
    "Knowledge - School Gardening for Early Childhood Education (ECE)",
    "Knowledge - Food Education for Early Childhood Education (ECE)",
    "Knowledge - Local Food Procurement for Early Childhood Education (ECE)",
    "Knowledge - Culturally Relevant School Meal Recipes",
    "Knowledge - Culturally Relevant Food Education",
    "Knowledge - Indigenous Food Education",
    "Knowledge - Building Relationships with Indigenous Food Producers",
    "Knowledge- Centering Food Justice, Equity, and Inclusion in Farm to School Programs",
    "Mentorship",
    "Leadership Experience",
    "Networking (Virtual)",
    "Networking (In-person)",
    "Policy/Advocacy",
    "Professional Development/Training",
    "Technical Assistance",
    "Funding Opportunities",
    "Resources",
    "Research",
    "Program Promotion & Spotlights",
    "Upcoming Events",
    "Newsletters",
    "Connect with School Food Buyers",
    "Connect with Local Producers",
    "Other",
  ];

  const handleMemberGainsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(typeof value === "string" ? value.split(",") : value);
    setMemberGainsError(value.length === 0);
  };

  return (
    <FormControl sx={{ width: "100%" }} size="small" error={memberGainsError}>
      <InputLabel>Select all that apply*</InputLabel>
      <div className="flex flex-col gap-y-3">
        <div>
          <Select
            multiple
            fullWidth
            error={memberGainsError}
            value={selectedTags}
            onChange={handleMemberGainsChange}
            label="Select all that apply*"
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {memberGains.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {memberGainsError && (
            <FormHelperText error>
              This field is required
            </FormHelperText>
          )}
        </div>
        {selectedTags.includes("Other") && (
          <TextField
            label="Other*"
            multiline
            rows={4}
            fullWidth
            value={otherGains}
            onChange={(e) => setOtherGains(e.target.value)}
          />
        )}
      </div>
    </FormControl>
  );
}