import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import Axios from "axios";
import ".././config";

const AddNewOrganization = ({
  addOrganization,
  setShowAddNewOrg,
}) => {
  const [orgName, setOrgName] = useState("");
  const [orgType, setOrgType] = useState("");
  const [otherOrgType, setOtherOrgType] = useState("");
  const [orgWebsite, setOrgWebsite] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [orgCity, setOrgCity] = useState("");
  const [orgZip, setOrgZip] = useState("");
  const [includeOnMap, setIncludeOnMap] = useState(false);
  const [profilePosition, setProfilePosition] = useState("");
  const [addingNewOrg, setAddingNewOrg] = useState(false);
  const [error, setError] = useState(null);

  const isValid = () =>
    orgName && orgType && profilePosition && (orgType !== 'other' || otherOrgType);

  const isAddressComplete = orgAddress && orgCity && orgZip;

  const handleAddOrganization = async () => {
    const newOrg = {
      name: orgName,
      type: orgType === 'other' ? otherOrgType : orgType,
      website: ensureHttps(orgWebsite),
      address: orgAddress,
      city: orgCity,
      zip: orgZip,
      isPublic: includeOnMap,
    };

    setAddingNewOrg(true);
    setError(null);

    try {
      const addedOrgId = await handleAddNewOrganization(newOrg);

      addOrganization({
        _id: addedOrgId,
        name: orgName,
        role: profilePosition,
      });

      setAddingNewOrg(false);
      setShowAddNewOrg(false);
      resetForm();
    } catch (error) {
      console.error("Error adding organization:", error);
      setAddingNewOrg(false);
      setError("Failed to add organization. Please try again.");
    }
  };

  const handleAddNewOrganization = async (newOrg) => {
    try {
      const response = await Axios.post(
        `${global.config.domain}/express-api/add-organization2`,
        newOrg
      );
      return response.data.orgId;
    } catch (error) {
      console.error("Error in API call to add new organization:", error);
      throw error;
    }
  };

  const resetForm = () => {
    setOrgName("");
    setOrgType("");
    setOtherOrgType("");
    setOrgWebsite("");
    setOrgAddress("");
    setOrgCity("");
    setOrgZip("");
    setIncludeOnMap(false);
    setProfilePosition("");
    setError(null);
  };

  const ensureHttps = (url) => {
    if (url && !url.match(/^https?:\/\//)) {
      return `https://${url}`;
    }
    return url;
  };

  const isValidWebsite = (url) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i' // fragment locator
    );
    return pattern.test(url);
  };

  return (
    <div className="flex-col justify-center gap-y-5">
      <Typography variant="subtitle1" className="font-bold text-light-green">
        Create your organization:
      </Typography>
      <br />
      <div className="flex justify-center">
        <div className="flex flex-col max-w-3xl w-full gap-y-5">
          <TextField
            required
            label="Organization Name"
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
            fullWidth
          />

          <TextField
            label="Website"
            value={orgWebsite}
            onChange={(e) => setOrgWebsite(e.target.value)}
            fullWidth
            error={orgWebsite && !isValidWebsite(ensureHttps(orgWebsite))}
            helperText={orgWebsite && !isValidWebsite(ensureHttps(orgWebsite)) ? "Please enter a valid website" : ""}
          />

          <TextField
            label="Primary Address"
            value={orgAddress}
            onChange={(e) => setOrgAddress(e.target.value)}
            fullWidth
          />

          <TextField
            label="Primary City"
            value={orgCity}
            onChange={(e) => setOrgCity(e.target.value)}
            fullWidth
          />

          <TextField
            label="Primary Zipcode"
            value={orgZip}
            onChange={(e) => setOrgZip(e.target.value)}
            fullWidth
          />

          {isAddressComplete && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeOnMap}
                  onChange={(e) => setIncludeOnMap(e.target.checked)}
                />
              }
              label="Include your organization on the Network Map?"
            />
          )}

          <TextField
            required
            label="Your Position/Title"
            value={profilePosition}
            onChange={(e) => setProfilePosition(e.target.value)}
            fullWidth
          />

          <FormControl component="fieldset" required>
            <Typography variant="subtitle1" className="font-bold">
              Organization Type
            </Typography>
            <RadioGroup
              value={orgType}
              onChange={(e) => setOrgType(e.target.value)}
            >
              <Box display="flex" flexDirection="row" width="100%">
                <Box flex={1}>
                  <FormControlLabel value="non-profit" control={<Radio />} label="Non-profit" />
                  <FormControlLabel value="for-profit" control={<Radio />} label="For-profit" />
                </Box>
                <Box flex={1}>
                  <FormControlLabel value="government" control={<Radio />} label="Government" />
                  <FormControlLabel value="school-education" control={<Radio />} label="School/Education" />
                </Box>
              </Box>
              <Box width="100%">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other:"
                  />
                  <TextField
                    value={otherOrgType}
                    onChange={(e) => setOtherOrgType(e.target.value)}
                    onClick={() => setOrgType('other')}
                    placeholder="Please specify"
                    style={{ marginLeft: '10px', flexGrow: 1 }}
                    disabled={orgType !== 'other'}
                  />
                </div>
              </Box>
            </RadioGroup>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleAddOrganization}
            disabled={!isValid() || addingNewOrg}
          >
            {addingNewOrg ? "Adding..." : "Add Organization"}
          </Button>

          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewOrganization;