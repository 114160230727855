import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from "@mui/material";

export default function SelectCOP({ selectedTags, setSelectedTags }) {
  const communities = [
    "School Garden & Food Education",
    "Local Food in Schools (Procurement & Production)",
  ];

  const handleChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTags([...selectedTags, value]);
    } else {
      setSelectedTags(selectedTags.filter((tag) => tag !== value));
    }
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <Grid container spacing={2}>
        {communities.map((cop) => (
          <Grid item xs={12} sm={6} key={cop}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTags.includes(cop)}
                  onChange={handleChange}
                  value={cop}
                />
              }
              label={cop}
            />
          </Grid>
        ))}
      </Grid>
    </FormControl>
  );
}