import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Radio, RadioGroup, FormLabel } from "@mui/material";
import SelectDemographic from "../components/SelectDemographic";
import { React, useState } from "react";
import Axios from "axios";
import { cities, counties, userGroups } from "../utils/constants";
import SelectOrganization from "../components/SelectOrganization";
import SelectUserTags from "../components/SelectUserTags";
import SelectMemberGains from "../components/SelectMemberGains";
import SelectNutritionPrograms from "../components/SelectNutritionPrograms";
import SelectCOP from "../components/SelectCOP";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Cancel } from "@mui/icons-material";
import AddNewOrganization from "../components/AddNewOrganization";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { roles as rolesList } from "../utils/constants";
import updateForumSettings from "../utils/updateForumSettings";
import './Membership.css';
import "../config";

const MembershipForm2 = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [isPartOfOrganization, setIsPartOfOrganization] = useState(null);
  const [showAddNewOrg, setShowAddNewOrg] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [additionalComments, setAdditionalComments] = useState("");
  const [completedOrgs, setCompletedOrgs] = useState([]);
  const [previousOrgs, setPreviousOrgs] = useState([]);
  const [newOrgs, setNewOrgs] = useState([]);
  const [userTags, setUserTags] = useState([]);
  const [memberGains, setMemberGains] = useState([]);
  const [otherGains, setOtherGains] = useState([]);
  const [memberGainsError, setMemberGainsError] = useState(false);
  const [showOrgForm, setShowOrgForm] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [interestedPrograms, setInterestedPrograms] = useState([]);
  const [activePrograms, setActivePrograms] = useState([]);
  const [foodItemsActivelyBought, setFoodItemsActivelyBought] = useState("");
  const [foodItemsInterestedInBuying, setFoodItemsInterestedInBuying] =
    useState("");
  const [foodItemsActivelySelling, setFoodItemsActivelySelling] = useState("");
  const [foodItemsInterestedInSelling, setFoodItemsInterestedInSelling] =
    useState("");
  const [organizationError, setOrganizationError] = useState(false);
  const [userGroupError, setUserGroupError] = useState(false);
  const [otherRole, setOtherRole] = useState("");
  const [isOtherRoleChecked, setIsOtherRoleChecked] = useState(false);
  const [otherRoles, setOtherRoles] = useState([{ value: '', checked: false }]);
  const [demographicData, setDemographicData] = useState({
    ageGroup: '',
    gender: '',
    otherGenderDescription: '',
    race: [],
    otherRaceDescription: '',
    county: '',
    city: '',
  });
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleChangeCity = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleChangeCounty = (event) => {
    setSelectedCounty(event.target.value);
  };

  const handleChangeRoles = (role) => (event) => {
    if (event.target.checked) {
      setSelectedRoles((prevRoles) => [...prevRoles, role]);
      setUserGroupError(false);
    } else {
      setSelectedRoles((prevRoles) => {
        const newRoles = prevRoles.filter((r) => r !== role);
        // Check if any roles are selected or any "Other" role has text
        setUserGroupError(
          newRoles.length === 0 && !otherRoles.some(role => role.value !== '')
        );
        return newRoles;
      });
    }
  };

  const addOrganization = (newOrg) => {
    setCompletedOrgs((prevState) => [...prevState, newOrg]);
  };

  const submitFormToBackend = async (formData) => {
    try {
      const response = await fetch(
        `${global.config.domain}/express-api/submit-form2`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const json = await response.json();
      console.log(json);
      return json;
    } catch (error) {
      console.error(
        "There was a problem with the fetch operation: " + error.message
      );
    }
  };

  const notifyAdmin = async (user) => {
    try {
      await Axios.post(
        `${global.config.domain}/express-api/new-member-request`,
        {
          fullName: user.fullname,
          username: user.username,
          email: user.email,
        }
      );
      console.log("Admin notification sent successfully");
    } catch (error) {
      console.error("Error notifying admin:", error);
    }
  };

  const handleOtherRoleChange = (index, value) => {
    const newOtherRoles = [...otherRoles];
    newOtherRoles[index].value = value;
    newOtherRoles[index].checked = value !== '';
    setOtherRoles(newOtherRoles);
  
    // Update userGroupError
    setUserGroupError(selectedRoles.length === 0);
  
    // Add a new empty "Other" role if all existing ones are filled
    if (newOtherRoles.every(role => role.value !== '')) {
      setOtherRoles([...newOtherRoles, { value: '', checked: false }]);
    }
  
    // Remove extra empty "Other" roles
    const filledRoles = newOtherRoles.filter(role => role.value !== '');
    if (filledRoles.length < newOtherRoles.length - 1) {
      setOtherRoles([...filledRoles, { value: '', checked: false }]);
    }
  };
  
  const handleOtherRoleCheck = (index, checked) => {
    const newOtherRoles = [...otherRoles];
    newOtherRoles[index].checked = checked;
    setOtherRoles(newOtherRoles);
  };

  const handleOrganizationChange = (event) => {
    const value = event.target.value;
    setIsPartOfOrganization(value === 'true');
    if (value === 'false') {
      setSelectedOrg({ name: "Independent Contractor" });
      setShowAddNewOrg(false);
    } else {
      setSelectedOrg(null);
    }
  };

  const isValid =
  (selectedRoles.length > 0 || otherRoles.some(role => role.checked && role.value !== '')) &&
  memberGains.length > 0 &&
  (isPartOfOrganization ? completedOrgs.length > 0 : true);

  const submitForm = () => {
    if (
      (selectedRoles.length === 0 && !otherRoles.some(role => role.checked && role.value !== ''))
    ) {
      setUserGroupError(true);
    }
    if (memberGains.length === 0) {
      setMemberGainsError(true);
    }
    if (isPartOfOrganization && completedOrgs.length === 0) {
      setOrganizationError(true);
    }
    if (isValid) {
      const validOtherRoles = otherRoles.filter(role => role.checked && role.value !== '');
      const allRoles = [...selectedRoles, ...validOtherRoles.map(role => role.value)];

      const orgNameAndPositions = completedOrgs.map((org) => ({
        _id: org._id,
        name: org.name,
        role: org.role,
      }));

      const membership2Data = {
        roles: allRoles,
        organizations: orgNameAndPositions,
        communitiesOfPractice: communities,
        memberGains: {
          selectedGains: memberGains,
          otherGain: otherGains,
        },
        demographic: {
          ...demographicData,
          city: selectedCity,
          county: selectedCounty,
        },
        additionalComments: additionalComments,
      };

      if (userTags.includes("Local Producer")) {
        membership2Data.foodItemsInterestedInSelling = foodItemsInterestedInSelling;
        membership2Data.foodItemsActivelySelling = foodItemsActivelySelling;
      } else if (userTags.includes("Food Buyer")) {
        membership2Data.activePrograms = activePrograms;
        membership2Data.interestedPrograms = interestedPrograms;
        membership2Data.foodItemsActivelyBought = foodItemsActivelyBought;
        membership2Data.foodItemsInterestedInBuying = foodItemsInterestedInBuying;
      }

      submitFormToBackend({ membership2: membership2Data });
      notifyAdmin(user); // Send email to support@azfarmtoschool.org
      console.info("Attempted admin notification");
      updateForumSettings(user.uid, { showemail: "1", showfullname: "1" });

      navigate("/thank-you");
    }
  };

  useEffect(() => {
    if (user) {
      setSelectedRoles(user.groups ? user.groups : []);
      setUserTags(user.tags ? user.tags : []);
      setCompletedOrgs(user.organizations ? user.organizations : []);
      setPreviousOrgs(user.organizations ? user.organizations : []);
      setSelectedCity(user.city);
      setSelectedCounty(user.county);
      setCommunities(
        user.communitiesofpractice ? user.communitiesofpractice : []
      );
      setMemberGains(user.hopetogain ? user.hopetogain : []);
      setOtherGains(user.otherGains);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.memberstatus === "pending" || !user["email:confirmed"]) {
        navigate("/");
      }
    }
  }, [user]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="flex flex-col m-12 justify-center gap-y-7">
      <div className="flex flex-col justify-center gap-y-5">
        <div className="text-4xl font-[Kindest] text-light-green text-center">
          Network Membership Form
        </div>
        <Divider />
        <div className="flex flex-col gap-y-5">
          <div className="flex justify-center">
            <div className="flex flex-col max-w-3xl w-full gap-y-3">
              {/* Roles section */}
              <div>
                <Typography 
                  variant="h6" 
                  className="text-center mb-4 font-bold text-light-green"
                >
                  Which roles do you identify with?
                </Typography>
                <br />
                <div className="flex">
                  <FormControl
                    error={userGroupError && !isOtherRoleChecked}
                    component="fieldset"
                    variant="standard"
                    className="w-full"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-1">
                      <div className="col-span-1 md:col-span-2 mb-2">
                        <Typography variant="subtitle1" className="font-bold text-light-green">Education</Typography>
                      </div>
                      {['Educator', 'Student', 'Parent'].map((role) => (
                        <FormControlLabel
                          key={role}
                          control={
                            <Checkbox
                              checked={selectedRoles.includes(role)}
                              onChange={handleChangeRoles(role)}
                            />
                          }
                          label={role}
                        />
                      ))}

                      <div className="col-span-1 md:col-span-2 mt-4 mb-2">
                        <Typography variant="subtitle1" className="font-bold text-light-green">Food Service</Typography>
                      </div>
                      {['School Food Buyer', 'Child Nutrition Professional', 'Local Food Distributor', 'Local Food Aggregator'].map((role) => (
                        <FormControlLabel
                          key={role}
                          control={
                            <Checkbox
                              checked={selectedRoles.includes(role)}
                              onChange={handleChangeRoles(role)}
                            />
                          }
                          label={role}
                        />
                      ))}

                      <div className="col-span-1 md:col-span-2 mt-4 mb-2">
                        <Typography variant="subtitle1" className="font-bold text-light-green">Agriculture</Typography>
                      </div>
                      {['Local Farmer', 'Local Rancher', 'Gardener'].map((role) => (
                        <FormControlLabel
                          key={role}
                          control={
                            <Checkbox
                              checked={selectedRoles.includes(role)}
                              onChange={handleChangeRoles(role)}
                            />
                          }
                          label={role}
                        />
                      ))}

                      <div className="col-span-1 md:col-span-2 mt-4 mb-2">
                        <Typography variant="subtitle1" className="font-bold text-light-green">Administration & Policy</Typography>
                      </div>
                      {['Coordinator', 'Administrator', 'State Legislator/Policy Maker', 'Local Government Official', 'State Government Official', 'Federal Government Official'].map((role) => (
                        <FormControlLabel
                          key={role}
                          control={
                            <Checkbox
                              checked={selectedRoles.includes(role)}
                              onChange={handleChangeRoles(role)}
                            />
                          }
                          label={role}
                        />
                      ))}

                      <div className="col-span-1 md:col-span-2 mt-4 mb-2">
                        <Typography variant="subtitle1" className="font-bold text-light-green">Other Roles</Typography>
                      </div>
                      {['SNAP-Ed Specialist', 'Volunteer'].map((role) => (
                        <FormControlLabel
                          key={role}
                          control={
                            <Checkbox
                              checked={selectedRoles.includes(role)}
                              onChange={handleChangeRoles(role)}
                            />
                          }
                          label={role}
                        />
                      ))}
                      {otherRoles.map((role, index) => (
                        <div key={index} className="col-span-1 md:col-span-2">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={role.checked}
                                onChange={(e) => handleOtherRoleCheck(index, e.target.checked)}
                                color="primary"
                              />
                            }
                            label={
                              <div className="flex items-center">
                                Other:
                                <TextField
                                  variant="standard"
                                  sx={{ marginLeft: "10px", marginBottom: "-2px", width: "200px" }}
                                  value={role.value}
                                  onChange={(e) => handleOtherRoleChange(index, e.target.value)}
                                  placeholder="Please specify"
                                  disabled={!role.checked}
                                />
                              </div>
                            }
                          />
                        </div>
                      ))}
                    </div>
                    {userGroupError && !otherRoles.some(role => role.value !== '') && (
                      <FormHelperText error>Please identify with at least one role.</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>
  
                      <br />
              <Divider />
                    <br />
  
              {/* Organization section */}
              <div>
                <Typography 
                  variant="h6" 
                  className="text-center mb-4 font-bold text-light-green"
                >
                  Are you part of an organization?
                </Typography>
                <div className="w-full max-w-3xl">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="organization"
                      name="organization"
                      value={isPartOfOrganization === null ? '' : isPartOfOrganization.toString()}
                      onChange={handleOrganizationChange}
                    >
                      <FormControlLabel value="true" control={<Radio />} label="Yes, I am part of an organization" />
                      <FormControlLabel value="false" control={<Radio />} label="No, I am not part of an organization" />
                    </RadioGroup>
                  </FormControl>
                </div>
  
                {isPartOfOrganization && (
                  <div className="w-full max-w-3xl mt-4">
                    <SelectOrganization
                      addOrganization={addOrganization}
                      setShowAddNewOrg={setShowAddNewOrg}
                      setSelectedOrg={setSelectedOrg}
                      selectedOrg={selectedOrg}
                      organizationError={organizationError}
                      setOrganizationError={setOrganizationError}
                    />
                    {showAddNewOrg && (
                      <AddNewOrganization
                        addOrganization={addOrganization}
                        setShowAddNewOrg={setShowAddNewOrg}
                      />
                    )}
                  </div>
                )}
              </div>
              <br />
              <Divider />
              <br />

  
              {/* Communities of Practice section */}
              <div className="flex justify-center">
                <div className="w-full max-w-3xl flex flex-col gap-y-3">
                  <Typography 
                    variant="h6" 
                    className="text-center mb-4 font-bold text-light-green"
                  >
                    What Communities of Practice(s) (CoP) are you interested in participating in?
                  </Typography>
                  <SelectCOP
                    selectedTags={communities}
                    setSelectedTags={setCommunities}
                  />
                </div>
              </div>
              <br />
              <Divider />
              <br />
  
              {/* Member Gains section */}
              <div className="flex justify-center">
                <div className="w-full max-w-3xl flex flex-col gap-y-3">
                  <Typography 
                    variant="h6" 
                    className="text-center mb-4 font-bold text-light-green"
                  >
                    What do you hope to gain from being a member of the Arizona Farm to School Network?
                  </Typography>
                  <SelectMemberGains
                    selectedTags={memberGains}
                    setSelectedTags={setMemberGains}
                    memberGainsError={memberGainsError}
                    setMemberGainsError={setMemberGainsError}
                    otherGains={otherGains}
                    setOtherGains={setOtherGains}
                  />
                </div>
              </div>
  
                  <br />
              <Divider />
              <br />
  
              {/* Demographic section */}
              <div className="flex justify-center">
                <div className="w-full max-w-3xl flex flex-col gap-y-3">
                  <SelectDemographic
                    demographicData={demographicData}
                    setDemographicData={setDemographicData}
                    cities={cities}
                    counties={counties}
                  />
                </div>
              </div>
  
                  <br />
              <Divider />
              <br />
  
              {/* Additional Comments section */}
              <div className="flex justify-center">
                <div className="w-full max-w-3xl flex flex-col gap-y-3">
                  <Typography 
                    variant="h6" 
                    className="text-center mb-4 font-bold text-light-green"
                  >
                    Additional Comments
                  </Typography>
                  <TextField
                    label="Additional comments"
                    multiline
                    rows={6}
                    fullWidth
                    onChange={(e) => setAdditionalComments(e.target.value)}
                  />
                </div>
              </div>
  <br />
              <Divider />
              {/* Submit button */}
              <div className="flex justify-center mt-4">
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    width: "fit-content",
                  }}
                  onClick={submitForm}
                >
                  Submit Form
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipForm2;
